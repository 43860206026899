<template>
  <section class="section section--cta">
    <div class="cta">
      <div class="cta__content">
        <div class="cta__title">
          <h2 class="title title-lg color-main-opposite">
            {{ _title }}
          </h2>
        </div>

        <div
          v-if="_description"
          class="cta__text text text-md color-main-opposite"
        >
          {{ _description }}
        </div>

        <div class="cta__action">
          <link-button
            :to="{ name: _routeName }"
            :value="_buttonValue"
            size="lg"
            color="white"
            transparent
            :compressed="btnCompressed"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LinkButton from '@/components/LinkButton'
export default {
  name: 'BuyNowSection',
  components: {
    LinkButton,
  },
  computed: {
    _title: {
      get() {
        return this.title || this.$t('web.servers_cta_title')
      },
    },
    _description: {
      get() {
        return this.description || this.description !== null
          ? this.$t('web.servers_cta_description')
          : null
      },
    },
    _routeName: {
      get() {
        return this.routeName || this.$ROUTER.NAME.PRICES
      },
    },
    _buttonValue: {
      get() {
        return (
          this.buttonValue ||
          this.$t('getVPN', {
            productName: process.env.VUE_APP__PRODUCT_NAME,
          })
        )
      },
    },
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: [String, null],
      default: '',
    },
    btnCompressed: {
      type: [Boolean, null],
      default: true,
    },
    buttonValue: {
      type: String,
      default: '',
    },
    routeName: {
      type: [String, null],
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
