<template>
  <main class="page servers-page">
    <section class="section servers__map servers-map">
      <div class="servers-map__background">
        <picture>
          <img
            :src="require('@/assets/image/servers/map.jpg')"
            alt="servers map"
            sizes="(max-width: 480px) 440px, 1600px"
            class="
              servers-map__background-image
              servers-map__background-image--desktop
            "
          />
          <img
            :src="require('@/assets/image/servers/map-mobile.png')"
            alt="servers map"
            sizes="(max-width: 480px) 440px, 1600px"
            class="
              servers-map__background-image
              servers-map__background-image--mobile
            "
          />
        </picture>
      </div>

      <div class="servers-map__content">
        <h2 class="map-content__title title title-xl color-main-opposite">
          {{ $t('web.servers_title') }}
        </h2>
        <p class="map-content__description text text-md color-main-opposite">
          {{
            $t('web.servers_title_description', {
              continents_number: continentsNumber,
              servers_number: serversNumber,
            })
          }}
        </p>
        <link-button
          class="map-content__button"
          :to="{ name: $ROUTER.NAME.PRICES }"
          :value="
            $t('getVPN', {
              productName: PRODUCT_NAME,
            })
          "
          size="lg"
          compressed
        />
      </div>
    </section>
    <section class="section servers__list servers-list">
      <h3 class="servers-list__title title title-xl" data-typograf>
        {{ $t('web.servers_locations_list_title') }}
      </h3>

      <div class="servers-table servers-list__table">
        <div class="servers-table__tabs">
          <div class="servers-table__inner">
            <button
              type="button"
              @click="setCurrentContinent('all')"
              class="servers-table__tab text text-lg"
              :class="{
                active: currentContinent === 'all',
              }"
            >
              {{ $t('web.servers_continent_all') }}
            </button>
            <button
              v-for="(continent, index) in continents"
              :key="index"
              @click="setCurrentContinent(continent)"
              type="button"
              class="servers-table__tab text text-lg"
              :class="{
                active: currentContinent === continent,
              }"
            >
              {{ translate('continent', continent) }}
            </button>
          </div>
        </div>

        <div class="servers-table__tables">
          <table class="servers-table__table">
            <thead>
              <tr>
                <th
                  class="servers-table__title text text-md color-main-opposite"
                >
                  {{ $t('web.allservers') }}
                </th>
                <th
                  v-for="(technology, index) in technologies"
                  :key="index"
                  class="text text-md color-main-opposite"
                >
                  {{ technology }}
                </th>
              </tr>
            </thead>

            <tbody class="servers-table__body">
              <tr v-for="(server, index) in filteredServers" :key="index">
                <td>
                  <span class="d-flex align-items-center flex-wrap">
                    <div class="d-flex">
                      <img
                        class="servers-list__server-icon flex-shrink-0"
                        :src="server.icon"
                        :alt="`icon_${server.code}`"
                      />
                      <span class="text">{{ server.title }}</span>
                    </div>
                    <span class="servers-list__mobile-properties text text-xs">
                      {{ server.technologyString }}
                    </span>
                  </span>
                </td>
                <template
                  v-for="(technology, index) in technologies"
                  :key="index"
                >
                  <td v-if="server.technologyList.includes(technology)">
                    <img
                      :src="require('@/assets/image/servers/plus.png')"
                      alt="plus"
                    />
                  </td>
                  <td v-else>
                    <img
                      :src="require('@/assets/image/servers/minus.png')"
                      alt="minus"
                    />
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <buy-now-section></buy-now-section>
  </main>
</template>

<script>
import LinkButton from '@/components/LinkButton'
import BuyNowSection from '@/components/Sections/BuyNow'
export default {
  name: 'index',
  components: {
    LinkButton,
    BuyNowSection,
  },
  data() {
    this.PRODUCT_NAME = process.env.VUE_APP__PRODUCT_NAME
    return {
      currentContinent: 'all',
    }
  },
  computed: {
    continentsNumber: {
      get() {
        return this.continents.length
      },
    },
    serversNumber: {
      get() {
        return this.servers.length
      },
    },
    continents: {
      get() {
        return Array.from(
          this.servers?.reduce((acc, server) => {
            acc.add(server.region)
            return acc
          }, new Set()) || []
        )
      },
    },
    filteredServers: {
      get() {
        return this.currentContinent === 'all'
          ? this.servers
          : this.servers.filter(
              (server) => server.region === this.currentContinent
            )
      },
    },
    servers: {
      get() {
        return this.$store.getters['country/UIServers'] || []
      },
    },
    technologies: {
      get() {
        return Array.from(
          this.servers?.reduce((acc, server) => {
            server.technologyList.forEach((technology) => {
              acc.add(technology)
            })
            return acc
          }, new Set()) || []
        )
      },
    },
  },
  methods: {
    setCurrentContinent(name) {
      this.currentContinent = name
    },
    translate(field, value) {
      switch (field) {
        case 'continent':
          return {
            asia: this.$t('web.continent_asia'),
            europe: this.$t('web.continent_europe'),
            america: this.$t('web.continent_america'),
          }[value.toLowerCase()]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
